import {
  Button,
  Container,
  Grid,
  Popover,
  Typography,
} from "@material-ui/core";
import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { SocialButton } from "../../Shared/Footer/Footer";

import useStyles from "./Gather.styles";

const Gather = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container style={{ padding: "32px 16px" }}>
      <Typography variant="h4" style={{ color: "#353b48", marginBottom: 32 }}>
        How We Gather
      </Typography>
      <Grid justify="center" container>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={12}
          md={4}
          item
        >
          <StaticImage
            src="../../../images/house-icon.png"
            width={200}
            placeholder="blurred"
            alt="Join in Person"
          />
          <Typography gutterBottom>Join us at Gonubie Farmers Hall</Typography>
          <a
            style={{ textDecoration: "none" }}
            href="https://goo.gl/maps/kZbCSmotUasR3GJZ9"
            target="_blank"
          >
            <Button variant="outlined">In Person</Button>
          </a>
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={12}
          md={4}
          item
        >
          <StaticImage
            src="../../../images/youtube-icon.png"
            width={200}
            placeholder="blurred"
            alt="Join Online"
          />
          <Typography gutterBottom>Watch on Youtube or Facebook</Typography>
          <Button onClick={handleClick} variant="outlined">
            Online
          </Button>
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={12}
          md={4}
          item
        >
          <StaticImage
            src="../../../images/lifehouse-at-home-icon.png"
            width={200}
            placeholder="blurred"
            alt="Join us at Home"
          />
          <Typography gutterBottom>
            Gather at your home and invite friends
          </Typography>
          <Link style={{ textDecoration: "none" }} to="at-home">
            <Button variant="outlined">At Home</Button>
          </Link>
        </Grid>
      </Grid>
      <SocialPopper anchorEl={anchorEl} onClose={handleClose} />
    </Container>
  );
};

export default Gather;

interface SocialPopperProps {
  anchorEl?: HTMLButtonElement;
  onClose?: () => void;
}

const SocialPopper = ({ anchorEl, onClose }: SocialPopperProps) => {
  const classes = useStyles();

  return (
    <Popover
      classes={{ paper: classes.popper }}
      anchorOrigin={{ horizontal: "center", vertical: "center" }}
      transformOrigin={{ horizontal: "center", vertical: "center" }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <SocialButton onClick={onClose} filled variant="youtube" />
      <SocialButton onClick={onClose} filled variant="facebook" />
    </Popover>
  );
};
