import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = (url: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      background: {
        background: `url(${url}) center center no-repeat`,
        backgroundSize: 'cover',
        height: '100vh',
        overflow: 'hidden',
      },
      serviceContainer: {
        position: 'absolute',
        bottom: 64,
        left: '10%',
      },
      day: {
        color: '#fafafa',
        textTransform: 'uppercase',
        '&:nth-of-type(2)': {
          borderBottom: '1px solid #fafafa',
          marginTop: 16,
          marginBottom: 2,
          paddingBottom: 0,
        },
      },
      when: {
        color: '#fafafa',
        textTransform: 'uppercase',
        fontSize: '11pt',
      },
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        paddingTop: 200,
      },
      title: {
        color: theme.palette.common.white,
        textAlign: 'center',
        textShadow: '2px 2px 10px #000',
        letterSpacing: 3,
        fontWeight: 400,
      },
      wordContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 16,
        '& p': {
          ...theme.typography.h3,
          letterSpacing: 4,
          position: 'relative',
          color: theme.palette.common.white,
          textShadow: '2px 2px 10px #000',
          transition: 'all 128ms ease-in',
        },
        '& p:not(:last-child):after': {
          content: '" "',
          width: 8,
          height: 8,
          backgroundColor: theme.palette.common.white,
          borderRadius: 10,
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: -28,
          margin: 'auto',
          boxShadow: '2px 2px 10px #000',
          transition: 'all 200ms ease-in',
        },
        '& p:not(:last-child)': {
          marginRight: 48,
        },
      },
      small: {
        '& p': {
          fontSize: '16pt',
        },
        '& p:not(:last-child):after': {
          width: 6,
          height: 6,
        },
      },
    })
  );

export default useStyles;
