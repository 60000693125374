import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";

import useStyles from "./Hero.styles";
import clsx from "clsx";

const Hero = () => {
  const { hero } = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  `);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const classes = useStyles(hero.childImageSharp.original.src)();

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <Typography className={classes.title} variant="h4">
          Welcome to Lifehouse Church
        </Typography>
        <span
          className={clsx([
            classes.wordContainer,
            { [classes.small]: matches },
          ])}
        >
          <Typography>Discover</Typography>
          <Typography>Grow</Typography>
          <Typography>Go</Typography>
        </span>
      </div>
    </div>
  );
};

export default Hero;
