import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
    },
    media: {
      height: 220,
    },
    title: {
      '& p': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    },
    all: {
      color: '#6190a2',
      textDecoration: 'none',
      fontSize: '24pt',
      marginLeft: theme.spacing(),
      '&:hover': {
        color: '#434f5b'
      }
    }
  }),
);

export default useStyles;