import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import useStyles from './Connected.styles';

import RightArrow from '@material-ui/icons/ArrowRightAlt';

const Connected = () => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { regex: "/ministry/" } }) {
        edges {
          node {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  `);

  const images: string[] = allFile.edges
    .filter((e) => e.node.childImageSharp)
    .map((e) => e.node.childImageSharp.original.src);

  const classes = useStyles();

  return (
    <Container style={{ marginBottom: 140 }}>
      <Typography
        variant="h4"
        style={{
          margin: '72px 0',
          textAlign: 'center',
          textTransform: 'uppercase',
          letterSpacing: 3,
        }}
      >
        Get Connected
      </Typography>
      <Grid spacing={4} container>
        <MinistryCard
          title="Lifehouse Kids"
          img={images.find((i) => i.includes('ministry-kids'))}
        />
        <MinistryCard
          title="Lifehouse Youth"
          img={images.find((i) => i.includes('ministry-youth'))}
        />
        <MinistryCard
          title="Lifehouse Groups"
          img={images.find((i) => i.includes('ministry-men'))}
        />
        <MinistryCard
          title="Equip Yourself"
          img={images.find((i) => i.includes('ministry-women'))}
        />
        <MinistryCard
          title="Volunteers"
          img={images.find((i) => i.includes('ministry-women'))}
        />
      </Grid>
    </Container>
  );
};

interface Props {
  img: string;
  title: string;
}

const MinistryCard = ({ img, title }: Props) => {
  const classes = useStyles();

  return (
    <Grid xs={12} sm={6} md={3} item>
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia className={classes.media} image={img} title={title} />
          <CardContent>
            <Typography>{title}</Typography>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque pretium, nisi ut volutpat mollis, leo risus.
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button>
            More <RightArrow />
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default Connected;
