import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      padding: spacing(4, 0),
      backgroundColor: palette.grey[300],
      color: palette.primary.contrastText,
    },
    eventCardGrid: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    eventCard: {
      backgroundColor: '#f2f2f2',
      maxWidth: 280,
      height: '100%',
      border: `2px solid ${palette.grey[300]}`,
      transition: 'all 200ms ease-in',
      '&:hover': {
        borderWidth: 2,
        borderColor: palette.primary.dark,
      },
      '& a': {
        textDecoration: 'none',
      },
    },
    eventAction: {
      padding: spacing(2, 4),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& svg': {
        transition: 'all 200ms ease-in',
        height: spacing(8),
        width: spacing(8),
        marginBottom: spacing(),
      },
      '& p': {
        transition: 'all 200ms ease-in',
        textDecoration: 'none',
        fontWeight: '500',
        textTransform: 'uppercase',
      },
    },
  })
);

export default styles;
