import * as React from 'react';
import Layout from '../layouts/Layout';

import {
  Gather,
  Hero,
  Welcome,
  Sermons,
  ServiceBookings,
} from '../components/Home';

const Index = () => {
  return (
    <Layout transparent>
      <Hero />
      <Welcome />
      <ServiceBookings />
      <Gather />
      <Sermons />
    </Layout>
  );
};

export default Index;
