import * as React from 'react';
import useStyles from './ServiceBookings.styles';
import clsx from 'clsx';
import {
  Card,
  CardActionArea,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Link } from 'gatsby';

import EventIcon from '@material-ui/icons/Event';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const ServiceBookings = (props: Props): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const limit = 50;
  const lockdown = false;

  if (!lockdown) return null;

  return (
    <div className={clsx(classes.root)}>
      <Container style={{ padding: '32px 16px' }}>
        <Grid justify={matches ? 'center' : 'space-between'} container>
          <Grid xs={12} md={8} style={{ marginBottom: matches && 32 }} item>
            <Typography
              variant="h4"
              style={{ color: '#353b48', marginBottom: 32 }}
            >
              Lifehouse Church doors are open again!
            </Typography>
            {lockdown && (
              <React.Fragment>
                <Typography style={{ color: '#353b48', marginBottom: 16 }}>
                  If you want to join our in person services we need you to
                  please register so that we can secure your seat as currently
                  we are only allowed to host {limit} people. To secure your
                  spot, please register by clicking{' '}
                  <Link to="/events">here</Link>.
                </Typography>
                <Typography style={{ color: '#353b48' }}>
                  Spots are limited, book yours now
                </Typography>
              </React.Fragment>
            )}
          </Grid>
          {lockdown && (
            <Grid className={classes.eventCardGrid} md={4} item>
              <Card className={classes.eventCard} elevation={0}>
                <Link to="/events">
                  <CardActionArea className={classes.eventAction}>
                    <EventIcon style={{ color: '#353b48' }} />
                    <Typography style={{ color: '#353b48' }}>
                      Secure your spot now
                    </Typography>
                  </CardActionArea>
                </Link>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default ServiceBookings;
