import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import YoutubeVideo from '../../../globals/interfaces/youtubeVideo';

import useStyles from './Sermons.styles';

const Sermons = () => {
  const [videos, setVideos] = React.useState<YoutubeVideo[]>([]);
  const classes = useStyles();

  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          backendUrl
        }
      }
    }
  `);

  const { backendUrl } = site.siteMetadata;

  const fetchVideos = React.useCallback(async () => {
    try {
      const response = await fetch(`${backendUrl}/api/videos`);
      const videos: YoutubeVideo[] = await response.json();

      setVideos(videos);
    } catch (err) {
      console.log(err);
    }
  }, [backendUrl]);

  React.useEffect(() => {
    fetchVideos();
  }, [fetchVideos]);

  return (
    <Container>
      <Typography
        variant="h4"
        style={{ margin: '72px 0', textTransform: 'uppercase' }}
      >
        Sermons |
        <Typography component="span">
          <a
            className={classes.all}
            href="https://www.youtube.com/channel/UCQpAfQjMSBi-_0zKYrK4rwQ"
            target="_blank"
          >
            View All
          </a>
        </Typography>
      </Typography>
      <Grid
        spacing={2}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        container
      >
        {videos.map(({ id, ...rest }) => (
          <VideoCard key={id} {...rest} />
        ))}
      </Grid>
    </Container>
  );
};

interface Props {
  videoId: string;
  title: string;
  imageUrl: string;
}

const VideoCard = ({ videoId, imageUrl, title }: Props) => {
  const classes = useStyles();

  return (
    <Grid
      xs={12}
      sm={6}
      md={3}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      item
    >
      <Card className={classes.card}>
        <CardActionArea
          onClick={() => {
            const win = window.open(
              `https://www.youtube.com/watch?v=${videoId}`,
              '_blank'
            );
            win.focus();

            console.log(`${title} -> Clicked!`);
          }}
        >
          <CardMedia className={classes.media} image={imageUrl} title={title} />
          <CardContent className={classes.title}>
            <Typography>{title}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default Sermons;
