import { Container } from '@material-ui/core';
import React from 'react';

import YoutubeVideo from '../../Shared/YoutubeVideo/YoutubeVideo';

const Welcome = () => {
  return (
    <Container>
      <YoutubeVideo
        style={{ margin: '64px 0 32px 0' }}
        videoUrl="https://www.youtube.com/embed/_AetvaoB0Kk"
      />
    </Container>
  );
};

export default Welcome;
