import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }: Theme) => {
  return createStyles({
    popper: {
      display: 'flex',
      justifyContent: 'center',
      padding: spacing(2),
      '& a': {
        display: 'flex',
        padding: spacing(1.5),
        margin: spacing(0, 1),
        borderRadius: 4,
        fontSize: '20pt', 
      }
    }
  })
});

export default useStyles;